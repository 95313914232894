import { KainetSecurityCommonLibModule } from '@kainet/kainet-security-common-lib';
import { KainetCommonModule } from '@kainet/kainet-common-lib';
import { NgModule } from '@angular/core';
import { UserProfileComponent } from './pages/user-profile.component';
import { UserProfileRoutingModule } from './user-profile-routing.module';
import { MatIconModule } from '@angular/material/icon';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [
        UserProfileComponent
    ],
    imports: [
        UserProfileRoutingModule,
        KainetCommonModule,
        KainetSecurityCommonLibModule,
        MatIconModule,
        BrowserAnimationsModule,
        TranslateModule
    ],
    exports: []
})

export class UserProfileModule {
}