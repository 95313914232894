export const locale = {
    lang: 'it',
    data: {
        'USERPROFILE': {
            'EMAIL': 'Email',
            'FNAME': 'Nome',
            'SNAME': 'Cognome',
            'CHANGEPASS': 'CAMBIA PASSWORD',
            'CANCEL': 'ANNULLA',
            'SAVE': 'SALVA'
        },
        'ERR': {
            'EREQ': "L'email è obbligatorio",
            'FREQ': 'Il nome è obbligatorio',
            'SREQ': 'Il cognome è obbligatorio',
            'EVALID': 'Prego inserire una email valida',
            'FVALID': 'Prego inserire un nome valido',
            'SVALID': 'Prego inserire un cognome valido'
        }
    }
};
