import { UserProfileModule } from './layout/components/user-profile/user-profile.module';
import { CalendarPlannerModule } from '../@fuse/components/calendar-planner/calendar-planner.module';
import { LoginModule } from '@fuse/components/login/login.module';


import { KainetCollaborationLibModule, CollaborationComponent } from '@kainet/kainet-collaboration-lib';
import { AssetComponent, KainetAssetLibModule, AssetsComponent } from '@kainet/kainet-asset-lib';
import { KainetCommonModule } from '@kainet/kainet-common-lib';
import { ProductsComponent, ProductComponent, WarehouseComponent, WorkOrdersComponent, KainetMesLibModule } from '@kainet/kainet-mes-lib';
import { MaintenanceModule, MaintenanceHomeComponent } from '@kainet/kainet-maintenance-lib';
import { KainetReportLibModule, ReportManagerComponent } from '@kainet/kainet-report-lib';
import { KainetAiLibModule,PlanningTabsComponent } from '@kainet/kainet-ai-lib'
import { AuthGuardService as AuthGuard, KainetSecurityCommonLibModule, GlobalHttpInterceptor } from '@kainet/kainet-security-common-lib';
import { DashboardsComponent, ProcessManagerService, KainetProcessManagerLibModule, ProcessManagerComponent, DashboardManagerComponent } from '@kainet/kainet-process-manager-lib';
import { PlannerConfigurationComponent, KainetPlannerLibModule, ProlatUploadDialogComponent, ProlatHistoryComponent } from '@kainet/kainet-planner-lib';
import { UsersComponent, GroupsComponent, KainetSecurityLibModule} from '@kainet/kainet-security-lib';
import { KainetDigitalPlantLibComponent, KainetDigitalPlantLibModule} from '@kainet/kainet-digital-plant-lib';

import { CalendarModule } from '@fuse/components/calendar/calendar.module';
import { MatInputModule } from '@angular/material/input'
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule } from '@fuse/components';
import { fuseConfig } from 'app/fuse-config';
import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { UtilsService } from '@core/services/utils.service';
import { BrowserWindowRef } from '@core/services/window.service';
import { CoreModule } from '@core/core.module';
import { ToastrModule } from 'ngx-toastr';


const appRoutes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'mes/products',
        component: ProductsComponent,
        data: {
          module: 'public',
        },
      },
      {
        path: 'mes/product',
        component: ProductComponent,
        data: {
          module: 'public',
        },
      },
      {
        path: 'mes/warehouse',
        component: WarehouseComponent,
        data: {
          module: 'public',
        },
      },
      {
        path: 'mes/working-phases',
        component: WorkOrdersComponent,
        data: {
          module: 'public',
        },
      },

      {
        path: 'assets',
        component: AssetsComponent,
        data: {
          module: 'public',
        },
      },
      {
        path: 'assets/:id',
        component: AssetComponent,
        data: {
          module: 'public',
        },
      },
      {
        path: 'dashboard/:id',
        component: DashboardsComponent,
        data: {
          module: 'public',
        },
      },
      {
        path: 'process-manager',
        component: ProcessManagerComponent,
        data: {
          module: 'public',
        },
      },
      {
        path: 'dashboard-manager',
        component: DashboardManagerComponent,
        data: {
          module: 'public',
        },
      },
      {
        path: 'report',
        component: ReportManagerComponent,
        data: {
          module: 'public',
        },
      },
      {
        path: 'maintenance/home',
        component: MaintenanceHomeComponent,
        data: {
          module: 'public',
        },
      },
      {
        path: 'users',
        component: UsersComponent,
        data: {
          module: 'public',
        },
      },
      {
        path: 'groups',
        component: GroupsComponent,
        data: {
          module: 'public',
        },
      },
      {
        path: 'collaboration',
        component: CollaborationComponent,
        data: {
          module: 'public',
        },
      },
      {
        path: 'managerPlanner/configuration',
        component: PlannerConfigurationComponent,
        canActivate: [AuthGuard],
        data: {
          module: 'public',
        },
      },
      {
        path: 'prolat/prolatUpload',
        component: ProlatUploadDialogComponent,
        canActivate: [AuthGuard],
        data: {
          module: 'public',
        },
      },
      {
        path: 'prolat/history',
        component: ProlatHistoryComponent,
        canActivate: [AuthGuard],
        data: {
          module: 'public',
        },
      },
      {
        path: 'digital-plant',
        component: KainetDigitalPlantLibComponent,
        canActivate: [AuthGuard],
        data: {
          module: 'public',
        },
      },
      {
        path: 'planning',
        component: PlanningTabsComponent,
        canActivate: [AuthGuard],
        data: {
          module: 'public',
        },
      }
    ],
  },
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes),

    TranslateModule.forRoot(),

    // Material moment date module/*  */
    MatMomentDateModule,

    // Material
    MatButtonModule,
    MatIconModule,
    MatInputModule,

    // Fuse modules
    FuseModule.forRoot(fuseConfig),
    FuseProgressBarModule,
    FuseSharedModule,
    FuseSidebarModule,
    CalendarModule,
    LoginModule,
    // App modules
    LayoutModule,
    FlexLayoutModule,
    CoreModule,
    ToastrModule.forRoot(),
    KainetCommonModule,
    MaintenanceModule,
    KainetAssetLibModule.forRoot(),
    KainetSecurityCommonLibModule,
    KainetReportLibModule,
    KainetProcessManagerLibModule,
    KainetSecurityLibModule,
    KainetMesLibModule,
    KainetCollaborationLibModule,
    KainetPlannerLibModule,
    KainetDigitalPlantLibModule,
    KainetAiLibModule,
    CalendarPlannerModule,
    UserProfileModule,
  ],
  providers: [
    UtilsService,
    BrowserWindowRef,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GlobalHttpInterceptor,
      multi: true,
    },
    ProcessManagerService,
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {}
