export const locale = {
    lang: 'en',
    data: {
        'CALENDAR': {
            'CAL': 'Calendar',
            'NEXT': 'Next',
            'PREV': 'Previous'
        }
    }
};
