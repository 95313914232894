import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'reset-password-request',
  templateUrl: './reset-password-request.component.html',
  styleUrls: ['./reset-password-request.component.scss']
})
export class ResetPasswordRequestComponent implements OnInit {

  requestPasswordRequest = this.fb.group({
    email: ['']
  });

  constructor(
              private translate: TranslateService,
              public fb: FormBuilder) {
  }

  ngOnInit() {
  }

  sendResetPasswordLink() {
  }

}
