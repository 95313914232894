import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { WINDOW } from './window.service';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(@Inject(WINDOW) private window: Window) { }


  private headerS = new BehaviorSubject<string>('KAINET');

  public getQueryParam(name: string): string {
    const url = this.window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    const results = regex.exec(url);
    if (!results) {
      return null;
    }
    if (!results[2]) {
      return '';
    }
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  headerOB = () => { return this.headerS.asObservable() };

  headerRoot(rootHeader: string, add: boolean = false) {
    let root = '';
    if (add) {
      root = this.headerS.getValue() + ' / ' + rootHeader;
    } else {
      root = rootHeader;
    }
    return this.headerS.next(root);
  }
}
