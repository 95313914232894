import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from '@core/services/login.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { KaiTranslationLoaderService, NotificationService } from '@kainet/kainet-common-lib';
import { Subscription } from 'rxjs';
import { locale as english } from '../../i18n/en';
import { locale as italian } from '../../i18n/it';
import { SecurityCommonService } from '@kainet/kainet-security-common-lib';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {

  isExpiredPassword: boolean;

  resetToken = '';
  sub: Subscription;
  resetPassword: FormGroup = this._formBuilder.group({
    password: ['', Validators.required],
    confirmpassword: ['', Validators.required]
  }, { validators: this.password.bind(this) });




  constructor(
    private route: ActivatedRoute,
    public _formBuilder: FormBuilder,
    private loginService: LoginService,
    private notifyService: NotificationService,
    private router: Router,
    private kaiTranslationLoaderService: KaiTranslationLoaderService,
    private translateService: TranslateService 
  ) {
    this.kaiTranslationLoaderService.loadTranslations(english, italian);

  }

  ngOnInit() {
    this.isExpiredPassword = this.route.snapshot.data.expiredPassword;
    this.sub = this.route
      .queryParams
      .subscribe(params => {
        this.resetToken = params.resetToken || '';
      });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
  showToasterSuccess(): void {
    this.notifyService.notificationSuccess({ title: '', body: 'Change Password Success' });
  }

  showToasterError(errorResponse: HttpErrorResponse): void {

    this.notifyService.notificationError(
      { 

        title: this.translateService.instant('MESSAGES_AUTH.AUTH_ERROR'), 
        body: this.kaiTranslationLoaderService.transateError(errorResponse,'MESSAGES_AUTH')
      
      });
  }
  resetPasswordSubmit(): void {
    this.loginService.changePassword(this.resetPassword.get('password').value).subscribe(res => {
      this.notifyService.notificationSuccess(
        { 
          title: this.translateService.instant('MESSAGES_COLLAB.SUCCESS'), 
          body: this.translateService.instant('MESSAGES_COLLAB.FILE_DELETED') 
        });

      this.router.navigate(['/user-profile']);
    }, (error: HttpErrorResponse) => {
      this.showToasterError(error);
    });
  }
  password(formGroup: FormGroup): boolean {
    const { value: password } = formGroup.get('password');
    const { value: confirmPassword } = formGroup.get('confirmpassword');
    const matched: boolean = password === confirmPassword;
    if (password !== '' && confirmPassword !== '' && matched) {
      formGroup.controls.confirmpassword.setErrors(null);
      formGroup.controls.password.setErrors(null);

    } else if (password !== '' && confirmPassword !== '' && !matched) {
      formGroup.controls.confirmpassword.setErrors({ passwordNotMatch: true });
      formGroup.controls.password.setErrors({ passwordNotMatch: true });

    }
    return matched;
  }
}
