export const locale = {
    lang: 'it',
    data: {
        'NAV': {
            'PRODUCTION': 'Produzione',
            'DASHBOARD': 'Dashboard',
            'TELEMETRY':'Telemetry',
            'ADM': 'Amministrazione',
            'USER': 'Utente',
            'GROUP': 'Gruppo',
            'NODE': 'Nodo',
            'MNTN': 'Manutenzione',
            'ASSET': 'Asset Manager',
            'REPORT': 'Report',
            'COLLAB': 'Collaborazione',
            'MES': 'MES',
            'PRODUCT': 'Prodotto',
            'WAREHOUSE': 'Magazzino',
            'ORDER': 'Ordine',
            'PROCESS_MANAGER': 'Process manager',
            'DAHBOARD_MANAGER':'Dashboard manager',
            'DIGITAL_PLANT': 'Digital Plant',
            'PROLAT': 'Prolat',
            'CONFIGURATION': 'Configurazioni',
            'PUP': 'Upload',
            'HISTORY': 'Storico',
            'PLANNING':'Planning',
            'DATA_COLLECTION':'Takt time',
            'SERVICES':'Servizi',
            'ANALITICS':'Analitics'

        },
        'MENU':{
            'STAR_BTN_SELECTED':'Questa è la pagina di atterraggio',
            'STAR_BTN_UNSELECTED':'Cliccare sulla stella per selezione questa pagina di atterraggio',

        }
    }
};
