export const locale = {
    lang: 'it',
    data: {
        'CALENDAR': {
            'CAL': 'Calendario',
            'NEXT': 'Prossimo',
            'PREV': 'Precedente'
        }
    }
};
