import { BehaviorSubject } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UtilsService } from '@core/services/utils.service';

import { locale as english } from '../i18n/en';
import { locale as italian } from '../i18n/it';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { CommonUtilsService, KaiTranslationLoaderService, NotificationService, User } from '@kainet/kainet-common-lib';
import { AuthService } from '@kainet/kainet-security-common-lib';
import { UserService } from '@kainet/kainet-security-lib';
import { HttpErrorResponse } from '@angular/common/http';


@Component({
    selector: 'user-profile',
    templateUrl: './user-profile.component.html',
    styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

    userProfileFormGroup: FormGroup;
    user: User;
    username: string;
    private loading = new BehaviorSubject<boolean>(true);
    public loading$ = this.loading.asObservable();

    constructor(
        public _formBuilder: FormBuilder,
        private userService: UserService,
        private authService: AuthService,
        private router: Router,
        private utilsService: UtilsService,
        public notificationService: NotificationService,
        private commonUtilsService: CommonUtilsService,
        private _kaiTranslationLoaderService: KaiTranslationLoaderService,
    ) {
        this.userProfileFormGroup = this._formBuilder.group({
            email: ['', Validators.required],
            firstname: ['', Validators.required],
            surname: ['', Validators.required]
        });

        this._kaiTranslationLoaderService.loadTranslations(english, italian);
    }

    ngOnInit(): void {



        let items = [
            {
                displayName: 'NAVBAR.SETTINGS',
                redirect: '/user-profile'
            },
            {
            displayName: 'NAVBAR.MYPROFILE',
            redirect: '/user-profile'
            }
        ]
        this.commonUtilsService.sendBreadcrumb(items);

        this.utilsService.headerRoot('NAVBAR.MYPROFILE');
        this.username = this.authService.getLoggedUser();

        this.userService.getLoggedUserByToken().subscribe((res: User) => {
            this.user = res;
            if (res) {
                this.userProfileFormGroup.setValue({
                    email: res.email,
                    firstname: res.firstname,
                    surname: res.lastname
                });
                this.loading.next(false);
            }
        });
    }

    save(): void {
        this.user.email = this.userProfileFormGroup.get('email').value;
        this.user.firstname = this.userProfileFormGroup.get('firstname').value;
        this.user.lastname = this.userProfileFormGroup.get('surname').value;
        this.userService.updateUserByToken(this.user).subscribe((res: User) => {

            this.notificationService.notificationSuccess({ code:200, body: "Success", title:"Update User" });
            
        }, (error: HttpErrorResponse) => {return this.showToasterError(error.status, error.error.msg, 'Update User Error')});
        
    }
    showToasterError(code: number, message: string, title: string): void {
        this.notificationService.notificationError({ code, body: message, title });
      }

    cancel(): void  {
        this.userProfileFormGroup.setValue({
            email: this.user.email,
            firstname: this.user.firstname,
            surname: this.user.lastname
        });
    }
}
