export const locale = {
    lang: 'en',
    data: {
        CREATEPASS: {
            CREATEPASS: 'Create Password'
        },
        LOGIN: {
            USERNAME: 'Username',
            FORGOT: 'Forgot your password?',
            LOGIN: 'LOGIN'
        },
        RESET: {
            CHANGEPASS: 'Change password',
            REPEAT: 'Repeat password'
        },
        RESETREQ: {
            REQPASSRESET: 'Reset password',
            EMAILINVALID: 'Invalid email format'
        },
        GENERAL: {
            MISSINGPASS: 'Password is required',
            PASSAGAIN: 'Password again',
            NOMATCH: 'Passwords must match',
            USRNAMEERR: 'Username is required',
            USRINVALID: 'Please enter a valid username',
            PASSINVALID: 'Please enter a valid password',
            CANCEL: 'CANCEL',
            PASS: 'Password',
            SAVE: 'SAVE'
        },
        MESSAGES_AUTH:{
            GENERIC_ERROR:'Generic error',
            KAINET_PASSWORD_MUST_BE_DIFFERENT:'Password must be different from the previous one',
            KAINET_INVALID_USERNAME:'Invalid username',
            KAINET_USER_NOT_FOUND:'User not found',
            KAINET_DUPLICATE_USER:'Duplicate user',
            KAINET_USER_WITHOUT_GROUP_NOT_ALLOWED:'No group has been assigned to the user',
            KAINET_USER_CANT_BE_DISABLED:'This user cannot be disabled',
            KAINET_USER_NOT_ENABLED:'User not enabled',
            KAINET_USERNAME_NOT_FOUND:'Username not found',
            AUTH_ERROR:'Errore di autenticazione',
            SUCCESS:'Login success',
            KAINET_FORBIDDEN:'Invalid credential'
        }
    }
};
