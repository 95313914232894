export const locale = {
    lang: 'it',
    data: {
        CREATEPASS: {
            CREATEPASS: 'CREA PASSWORD'
        },
        LOGIN: {
            USERNAME: 'Nome utente',
            FORGOT: 'Password dimenticata?',
            LOGIN: 'LOGIN'
        },
        RESET: {
            CHANGEPASS: 'Cambia password',
            REPEAT: 'Ripeti password'
        },
        RESETREQ: {
            REQPASSRESET: 'Resetta la password',
            EMAILINVALID: 'Formato email non valido'
        },
        GENERAL: {
            MISSINGPASS: 'La password è obbligatoria',
            PASSAGAIN: 'Password di nuovo',
            NOMATCH: 'Le password devono essere identiche',
            USRNAMEERR: 'Il nome utente è obbligatorio',
            USRINVALID: 'Per favore inserisci un nome utente valido',
            PASSINVALID: 'Per favore inserisci un password valido',
            CANCEL: 'ANNULLA',
            PASS: 'Password',
            SAVE: 'SALVA'
        },
        MESSAGES_AUTH:{
            GENERIC_ERROR:'Errore generico',
            KAINET_PASSWORD_MUST_BE_DIFFERENT:'La password deve essere diversa dalla precedente',
            KAINET_INVALID_USERNAME:'Username non valido',
            KAINET_USER_NOT_FOUND:'Utente non trovato',
            KAINET_DUPLICATE_USER:'Utente duplicato',
            KAINET_USER_WITHOUT_GROUP_NOT_ALLOWED:'Non è stato asseganto nessun gruppo all\'utente',
            KAINET_USER_CANT_BE_DISABLED:'Questo utente non può essere disabilitato',
            KAINET_USER_NOT_ENABLED:'Utente non abilitato',
            KAINET_USERNAME_NOT_FOUND:'Username non trovato',
            AUTH_ERROR:'Errore di autenticazione',
            SUCCESS:'Login avvenuta con successo',
            KAINET_FORBIDDEN:'Credenziali errate'
        }
    }
};
