<div id="my-profile" class="inner-scroll" fxLayout="row" fxLayoutAlign="center">
    <div id="my-profile-form-wrapper">
      <div id="user-profile-form">
        <div class="header" fxLayoutAlign="flex-start" fxLayout="row">
            <mat-icon class="account-icon">account_circle</mat-icon>
            <div fxLayout="column">
                <span>{{ user?.firstname + ' ' + user?.lastname }}</span>
                <span>{{ user?.email }}</span>
            </div>
        </div>
        <form name="userProfileFormGroup" [formGroup]="userProfileFormGroup" (ngSubmit)="save()">
          <mat-card style="max-height: 200vh;">
            <mat-card-content>
              <div>
                <mat-form-field appearance="outline">
                  <mat-label>{{ 'USERPROFILE.EMAIL' | translate }}</mat-label>
                  <input matInput formControlName="email">
                  <mat-error *ngIf="userProfileFormGroup.get('email').hasError('required')">
                    {{ 'ERR.EREQ' | translate }}
                  </mat-error>
                  <mat-error *ngIf="!userProfileFormGroup.get('email').hasError('required')">
                    {{ 'ERR.EVALID' | translate }}
                  </mat-error>
                </mat-form-field>
                
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'USERPROFILE.FNAME' | translate }}</mat-label>
                    <input matInput formControlName="firstname">
                    <mat-error *ngIf="userProfileFormGroup.get('firstname').hasError('required')">
                      {{ 'ERR.FREQ' | translate }}
                    </mat-error>
                    <mat-error *ngIf="!userProfileFormGroup.get('firstname').hasError('required')">
                      {{ 'ERR.FVALID' | translate }}
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>{{ 'USERPROFILE.SNAME' | translate }}</mat-label>
                    <input matInput formControlName="surname">
                    <mat-error *ngIf="userProfileFormGroup.get('surname').hasError('required')">
                      {{ 'ERR.SREQ' | translate }}
                    </mat-error>
                    <mat-error *ngIf="!userProfileFormGroup.get('surname').hasError('required')">
                      {{ 'ERR.SVALID' | translate }}
                    </mat-error>
                </mat-form-field>
              </div>
              <div class="user-change-password" fxLayout="row" fxLayout.xs="column"
                fxLayoutAlign="center">
  
                  <div layout-gt-sm="column" class="change-password" fxLayoutAlign="center" fxLayout="row">
                      <mat-icon>
                        <img src="/assets/icons/material-icons/outline/profile-lock-orange.svg">
                      </mat-icon>
                      <a mat-button routerLink="/login/resetPassword" class="change-pass-button">
                        {{ 'USERPROFILE.CHANGEPASS' | translate }}
                      </a>
                  </div>
  
              </div>
            </mat-card-content>
            <hr/>
            <mat-card-actions>
              <div fxLayout="row" class="tb-action-button">
                <button class="cancel-button" mat-raised-button (click)="cancel()">
                  {{ 'USERPROFILE.CANCEL' | translate }}
                </button>
                <button class="save-button" mat-raised-button
                  (click)="save()" [disabled]="!user?.kainetUser">
                  {{ 'USERPROFILE.SAVE' | translate }}
                </button>
              </div>
            </mat-card-actions>
          </mat-card>
        </form>
      </div>
    </div>
  </div>
  <inner-loading *ngIf="loading$ | async" diameter="50px"></inner-loading>