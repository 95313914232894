import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToolbarService } from '@app/layout/components/toolbar/toolbar.service';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarMonthViewDay } from 'angular-calendar';
import { startOfDay } from 'date-fns';
import { Subject, Subscription } from 'rxjs';
import { locale as english } from '../calendar/i18n/en';
import { locale as italian } from '../calendar/i18n/it';



@Component({
    selector: 'calendar-planner',
    templateUrl: './calendar-planner.component.html',
    styleUrls: ['./calendar-planner.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class CalendarPlannerComponent implements OnInit, OnDestroy {

    actions: CalendarEventAction[];
    activeDayIsOpen = false;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    dialogRef: any;
    @Input() events: CalendarEvent[];
    refresh: Subject<any> = new Subject();
    public selectedDaySub: Subject<any> = new Subject();
    public closeModalSub: Subject<any> = new Subject();
    public viewDateChange: Subject<Date> = new Subject();
    selectedDay: any;
    view: 'month' | 'week' | 'day';
    viewDate: Date;
    locale: string;
    subscription: Subscription;
    constructor(
        private _matDialog: MatDialog,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _toolbarService: ToolbarService
    ) {
        // Set the defaults
        this.view = 'month';
        this.viewDate = new Date();
        this.activeDayIsOpen = true;
        this.selectedDay = { date: startOfDay(new Date()) };
        this._fuseTranslationLoaderService.loadTranslations(english, italian);
        this.subscription = this._toolbarService.currentValue.subscribe(message => this.locale = message);
    }
    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        if (typeof this.events === undefined) {
            this.events = new Array<CalendarEvent>();
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------


    /**
     * Before View Renderer
     *
     * @param {any} header
     * @param {any} body
     */
    beforeMonthViewRender({ header, body }): void {
        /**
         * Get the selected day
         */
        const _selectedDay = body.find((_day) => {
            return _day.date.getTime() === this.selectedDay.date.getTime();
        });

        if (_selectedDay) {
            /**
             * Set selected day style
             * @type {string}
             */
            _selectedDay.cssClass = 'cal-selected';
        }

    }

    /**
     * Day clicked
     *
     * @param {MonthViewDay} day
     */
    dayClicked(day: CalendarMonthViewDay): void {

        const date: Date = day.date;
        const events: CalendarEvent[] = day.events;

        // if (isSameMonth(date, this.viewDate)) {
        //     if ((isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) || events.length === 0) {
        //         this.activeDayIsOpen = false;
        //     }
        //     else {
        //         this.activeDayIsOpen = true;viewDateChange
        //         this.viewDate = date;
        //     }
        // }
        this.activeDayIsOpen = false;
        this.selectedDay = day;
        this.refresh.next();
        this.selectedDaySub.next(this.selectedDay);
    }

    /**
     * Event times changed
     * Event dropped or resized
     *
     * @param {CalendarEvent} event
     * @param {Date} newStart
     * @param {Date} newEnd
     */
    eventTimesChanged({ event, newStart, newEnd }: CalendarEventTimesChangedEvent): void {
        event.start = newStart;
        event.end = newEnd;
        this.refresh.next(true);
    }
    closeModal() {
        this.closeModalSub.next(true);

    }
    viewDateChangeEvent($event: Date) {
        this.viewDateChange.next($event);
        this.selectedDay = { date: $event };
    }
}


