import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constants, defaultHttpOptions } from '@kainet/kainet-common-lib';
import { Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { LoginRequest, LoginResponse } from '@kainet/kainet-common-lib';
import { JwtHelperService } from '@auth0/angular-jwt';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private jwtHelper = new JwtHelperService();
  private $loadSuccess = new Subject<boolean>();
  $loginSucces = this.$loadSuccess.asObservable();
  constructor(
    private http: HttpClient,
    private router: Router
  ) { }


  loadSuccess(event: boolean): void {
    this.$loadSuccess.next(event);
  }
    public activate(loginRequest: LoginRequest): Observable<LoginResponse> {
    return this.http.post<LoginResponse>('/api/admin/activate', loginRequest, defaultHttpOptions()).pipe(
      tap(() => {
        this.router.navigate(['/login']);
      }
      ));
  }

  public changePassword(password: string): Observable<LoginResponse> {
    return this.http.post<LoginResponse>('/api/admin/changepassword?password=' + password, null, defaultHttpOptions());
  }

    public login(loginRequest: LoginRequest): Observable<LoginResponse> {
    return this.http.post<LoginResponse>('/api/admin/authenticate', loginRequest, defaultHttpOptions()).pipe(
      tap((loginResponse: LoginResponse) => {
        if (loginResponse.firstLogin) {
          this.firstLogin(loginResponse.firstLogin, loginRequest.username);
        } else {
          this.setUserFromJwtToken(loginResponse.jwttoken, loginResponse.refreshToken, true);
        }
      }
      ));
  }

    firstLogin(firstLogin: boolean, user: string): void {
    if (firstLogin) {
      localStorage.setItem(Constants.localStorage.user, user);
      this.router.navigate(['login/createPassword']);
    }
  }

  public setUserFromJwtToken(jwtToken, refreshToken, notify): void {
    if (!jwtToken) {
      LoginService.clearTokenData();
    } else {
      this.updateAndValidateToken(jwtToken, 'jwt_token', true);
    }
  }

  private static clearTokenData(): void {
    localStorage.removeItem(Constants.localStorage.token);
    localStorage.removeItem('jwt_token_expiration');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('refresh_token_expiration');
  }

  private updateAndValidateToken(token, prefix, notify): void {
    let valid = false;
    const tokenData = this.jwtHelper.decodeToken(token);
    const issuedAt = tokenData.iat;
    const expTime = tokenData.exp;
    if (issuedAt && expTime) {
      const ttl = expTime - issuedAt;
      if (ttl > 0) {
        const clientExpiration = new Date().valueOf() + ttl * 1000;
        localStorage.setItem(prefix, token);
        localStorage.setItem(prefix + '_expiration', clientExpiration.toString());
        valid = true;
      }
    }
  }
}
