import { AppService } from './app.service';
import { LoginService } from './core/services/login.service';
import { Platform } from '@angular/cdk/platform';
import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { CalendarComponent } from '@fuse/components/calendar/calendar.component';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import {
  Constants,
  KaiTranslationLoaderService
} from '@kainet/kainet-common-lib';
import { CalendarPlannerComponent } from '@fuse/components/calendar-planner/calendar-planner.component';
import {
  RoleMapping,
  SecurityCommonService,
} from '@kainet/kainet-security-common-lib';
import { TranslateService } from '@ngx-translate/core';
import { locale as navigationEnglish } from 'app/navigation/i18n/en';
import { locale as navigationItalian } from 'app/navigation/i18n/it';
import { navigation } from 'app/navigation/navigation';
import { AssetTabItemService } from '@kainet/kainet-asset-lib';
import {
  ItemService,
  MaintenanceHomeComponent,
} from '@kainet/kainet-maintenance-lib';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ExternalIconService } from './core/services/external-icon.service';
import { GroupService } from '@kainet/kainet-security-lib/';

import { ActivatedRoute, NavigationEnd, Router, UrlSegment } from '@angular/router';
import { ItemPlannerService } from '@kainet/kainet-planner-lib';
import { ProcessManagerService } from '@kainet/kainet-process-manager-lib';
import { KainetDigitalPlantLibService } from '@kainet/kainet-digital-plant-lib';

@Component({
  selector: 'app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy{
  fuseConfig: any;
  navigation: any;
  // Private
  private _unsubscribeAll: Subject<any>;
  connected: Subscription;
  isConnected: boolean;

  /**
   * Constructor
   *
   * @param {DOCUMENT} document
   * @param {FuseConfigService} _fuseConfigService
   * @param {FuseNavigationService} _fuseNavigationService
   * @param {FuseSidebarService} _fuseSidebarService
   * @param {KaiTranslationLoaderService} _kaiTranslationLoaderService
   * @param {Platform} _platform
   * @param {TranslateService} _translateService
   */
  constructor(
    @Inject(DOCUMENT) private document: any,
    private _fuseConfigService: FuseConfigService,
    private _fuseNavigationService: FuseNavigationService,
    private _fuseSidebarService: FuseSidebarService,
    private _kaiTranslationLoaderService: KaiTranslationLoaderService,
    private _translateService: TranslateService,
    private _platform: Platform,
    private itemPlannerService: ItemPlannerService,
    _externalIconService: ExternalIconService,
    private securityCommonService: SecurityCommonService,
    private itemService: ItemService,
    private assetTabItemService: AssetTabItemService,
    private kainetDigitialPlantService: KainetDigitalPlantLibService,
    private route: ActivatedRoute,
    _fuseSplashScreenService: FuseSplashScreenService,
    private router: Router,
    private ls: LoginService,
    private groupService: GroupService,
    private processManagerService: ProcessManagerService,
    private appservice:AppService
  ) {
    // Get default navigation
    this.navigation = navigation;

    // Register the navigation to the service
    this._fuseNavigationService.register('main', this.navigation);

    // Set the main navigation as our current navigation
    this._fuseNavigationService.setCurrentNavigation('main');

    // Add languages
    this._translateService.addLangs(['en', 'it']);

    // Set the default language
    this._translateService.setDefaultLang('en');

    // Set the navigation translations
    this._kaiTranslationLoaderService.loadTranslations(
      navigationEnglish,
      navigationItalian
    );

    // Use a language
    this._translateService.use('en');

    // init custom icon 
    _externalIconService.initIcon();

    // Add is-mobile class to the body if the platform is mobile
    if (this._platform.ANDROID || this._platform.IOS) {
      this.document.body.classList.add('is-mobile');
    }

    // Set the private defaults
    this._unsubscribeAll = new Subject();



  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {

    this.router.events.subscribe(event=>{

      if(event instanceof NavigationEnd){
        if(event.url=="/"){
            this.appservice.getFirstNavigation();
        }

      }
    })


    this.ls.$loginSucces.subscribe((success) => {
      if (success) {
        this.appservice.getFirstNavigation();
      }
    });

    // Subscribe to config changes
    this.securityCommonService.tokenExpired().subscribe((expired) => {
      if (expired) {
        this.router.navigate(['login']);
      }
    });
    this.appservice.mapNotification();

    this.securityCommonService.pageChanged
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((roleMapping: Array<RoleMapping>) => {
        let roleMappingMap = new Map(
          roleMapping.map((key) => [key.roleName, key.isDisabled])
        );
          this.appservice.hideNoAuthenticatorMenu(roleMappingMap);

      });
    this._fuseConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this.fuseConfig = config;

        // Boxed
        if (this.fuseConfig.layout.width === 'boxed') {
          this.document.body.classList.add('boxed');
        } else {
          this.document.body.classList.remove('boxed');
        }

        // Color theme - Use normal for loop for IE11 compatibility
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < this.document.body.classList.length; i++) {
          const className = this.document.body.classList[i];

          if (className.startsWith('theme-')) {
            this.document.body.classList.remove(className);
          }
        }

        this.document.body.classList.add(this.fuseConfig.colorTheme);
      });

    /**
     * Maintenance Component init
     *
     * set in maintenacne component @fuse
     *
     */
    this.itemService.addComponet(CalendarComponent, null);
    this.itemPlannerService.addComponet(CalendarPlannerComponent, null);
    /**
     * Asset dynamic Component
     */

    this.assetTabItemService.$successEvent.subscribe((id) => {
      if (!isNaN(id)) {
        this.assetTabItemService.addComponet(
          MaintenanceHomeComponent,
          { assetMode: true, id: id },
          'Maintenance',
          'ROLE_MAINTENANCE_LIST'
        );
      }
    });

    /**
     *
     * Sono cambiate lo dashbord legate all'utente quindi ricarco il menu
     *
     */
    this.groupService.$onChangeDashboardGroup.subscribe(()=>{
      this.securityCommonService.roleNavigation();
    })





    this.kainetDigitialPlantService.$navigateToDashboard.subscribe((id:string)=>{
        this.appservice.navigateToDashboard(id);
    })
  }



  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebarOpen(key): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }

}
