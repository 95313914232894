import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { WINDOW_PROVIDERS } from './services/window.service';
import { ErrorsModule } from './errors/errors.module'
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    ErrorsModule

  ],
  providers: [
    WINDOW_PROVIDERS
  ],
})
export class CoreModule { }
