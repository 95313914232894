export const locale = {
    lang: 'it',
    data: {
        'NAVBAR': {
            'MYPROFILE': 'Profilo',
            'LOGOUT': 'Esci',
            'SETTINGS': 'Impostazioni'
        }
    }
};
