<div id="calendar" class="page-layout simple fullwidth">

    <!-- HEADER -->
    <div class="header p-16 p-sm-24" [ngClass]="viewDate | date:'MMM'">

        <div class="header-content" fxLayout="row" fxLayoutAlign="space-between">

            <div class="header-top" fxLayout="row" fxLayoutAlign="space-between center" fxLayout.xs="column">

                <div class="logo mb-16 mb-sm-0" fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon class="logo-icon" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                        today
                    </mat-icon>
                    <span class="logo-text" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                        {{ 'CALENDAR.CAL' | translate }}
                    </span>
                </div>
            </div>
            <!-- / TOOLBAR -->

            <!-- HEADER BOTTOM -->
            <div class="header-bottom" fxLayout="row" fxLayoutAlign="center center"
                [@animate]="{value:'*',params:{delay:'150ms'}}">

                <button mat-icon-button class="arrow" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
                    (viewDateChange)="viewDateChangeEvent($event)" aria-label="Previous">
                    <mat-icon>chevron_left</mat-icon>
                </button>

                <div class="title">
                    {{ viewDate | calendarDate:(view + 'ViewTitle'):locale }}
                </div>

                <button mat-icon-button class="arrow" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
                    (viewDateChange)="viewDateChangeEvent($event)" aria-label="Next">
                    <mat-icon>chevron_right</mat-icon>
                </button>
            </div>
            <div fxLayout="row" fxLayoutAlign="center center" (click)=closeModal()>
                <button mat-icon-button style="z-index: 1;">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
            <!-- / HEADER BOTTOM -->
        </div>

        <!-- ADD EVENT BUTTON -->
        <!-- <button mat-fab class="add-event-button mat-accent" (click)="addEvent()" aria-label="Add event"
                [@animate]="{value:'*',params:{delay:'300ms',scale:'0.2'}}">
            <mat-icon>add</mat-icon>
        </button> -->
        <!-- / ADD EVENT BUTTON -->
    </div>
    <!-- / HEADER -->

    <!-- CONTENT -->
    <div class="content" [@animate]="{value:'*',params:{delay:'200ms',y:'50px'}}">
        <div [ngSwitch]="view">
            <mwl-calendar-month-view *ngSwitchCase="'month'" [viewDate]="viewDate" [events]="events" [refresh]="refresh"
                [locale]="locale" [activeDayIsOpen]="false" (dayClicked)="dayClicked($event.day)" (eventTimesChanged)="eventTimesChanged($event)"
                (beforeViewRender)="beforeMonthViewRender($event)">
            </mwl-calendar-month-view>
        </div>
    </div>
    <!-- / CONTENT -->
</div>