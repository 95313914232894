<div id="login" class="inner-scroll" fxLayout="row" fxLayoutAlign="center">
  <div id="login-form-wrapper">

    <div id="login-form">

      <form name="loginFormGroup" [formGroup]="loginFormGroup" (ngSubmit)="login()">
        <mat-card style="max-height: 200vh;">
          <mat-card-title-group>
            <div class="logo">
              <img src="assets/images/logos/kainet-logo.svg">
            </div>
          </mat-card-title-group>
        
          <mat-card-content>
            <div class="input-field">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'LOGIN.USERNAME' | translate }}</mat-label>
                <input matInput formControlName="username">
                <mat-icon matSuffix class="secondary-text">
                  <img src="/assets/icons/login/account_circle-black.svg">
                </mat-icon>
                <mat-error *ngIf="loginFormGroup.get('username').hasError('required')">
                  {{ 'GENERAL.USRNAMEERR' | translate }}
                </mat-error>
                <mat-error *ngIf="!loginFormGroup.get('username').hasError('required')">
                  {{ 'GENERAL.USRINVALID' | translate }}
                </mat-error>
              </mat-form-field>
              
              <mat-form-field appearance="outline">
                <mat-label>Password</mat-label>
                <input matInput type="password" formControlName="password">
                <mat-icon matSuffix class="secondary-text">
                  lock
                </mat-icon>
                <mat-error>
                  {{ 'GENERAL.MISSINGPASS' | translate }}
                </mat-error>
              </mat-form-field>
            </div>
          </mat-card-content>
          <mat-card-actions>
            <div fxLayout="column" class="tb-action-button">
              <button mat-raised-button class="submit-button"
                [disabled]="loginFormGroup.invalid">
                {{ 'LOGIN.LOGIN' | translate }}
              </button>
            </div>
          </mat-card-actions>
        </mat-card>
      </form>
    </div>
  </div>
</div>