import { ChangeDetectorRef, Component, HostBinding, Input, OnDestroy, OnInit, Output,EventEmitter } from '@angular/core';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseNavigation, FuseNavigationItem } from '@fuse/types';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { CommonUtilsService } from '@kainet/kainet-common-lib';
import { MenuDefaultService } from '@core/services/menu-default.service';

@Component({
    selector: 'fuse-nav-vertical-item',
    templateUrl: './item.component.html',
    styleUrls: ['./item.component.scss']
})
export class FuseNavVerticalItemComponent implements OnInit, OnDestroy {
    @HostBinding('class')
    classes = 'nav-item';

    @Input()
    item: FuseNavigationItem;


    @Output()
    isDefoultHomePageEvent: EventEmitter<string>;

    
    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     */

    /**
     *
     * @param {ChangeDetectorRef} _changeDetectorRef
     * @param {FuseNavigationService} _fuseNavigationService
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        private commonUtilsService: CommonUtilsService,
        private menuDefaultService: MenuDefaultService
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this.isDefoultHomePageEvent= new EventEmitter();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to navigation item
        merge(
            this._fuseNavigationService.onNavigationItemAdded,
            this._fuseNavigationService.onNavigationItemUpdated,
            this._fuseNavigationService.onNavigationItemRemoved
        ).pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    emitMenuDefaultPage(menuId:string){
        this.menuDefaultService.saveDefaultMenu(menuId).subscribe(res => {
            this._fuseNavigationService.homePageChanged(menuId)
        })



    }
    sendBreadcrumb(item: FuseNavigation) {
        const parent: FuseNavigation = this._fuseNavigationService.getNavigationItemParent(item.id);

        const b = [];
        if (parent) {
            b.push({ displayName: parent.translate, redirect: parent.url });
        }
        b.push({ displayName: item.translate, redirect: item.url });
        this.commonUtilsService.sendBreadcrumb(b);


    }
}
