import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { navigation } from 'app/navigation/navigation';
// import { DashboardsService } from 'app/modules/production-manager/pages/dashboards/dashboards.service';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from '@kainet/kainet-security-common-lib';

@Component({
    selector: 'vertical-layout-1',
    templateUrl: './layout-1.component.html',
    styleUrls: ['./layout-1.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class VerticalLayout1Component implements OnInit, OnDestroy {
    fuseConfig: any;
    navigation: any;
    hiddeOnFullScreen: boolean;
    isLoggin = false;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        // private dashService: DashboardsService,
        private _authService: AuthService,
        private router: Router
    ) {
        // Set the defaults
        this.navigation = navigation;

        // Set the private defaults
        this._unsubscribeAll = new Subject();

        router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe((val: NavigationEnd) => {
                this.isLoggin = val.url.startsWith('/login');
            });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                this.fuseConfig = config;
            });

        // this.dashService.currentValue.subscribe(message => this.hiddeOnFullScreen = message); TODO

    }

    // //check if user is logged in to show header & menu
    // public get isLoggin(): boolean {
    //     return this._authService.isAuthenticated();
    // }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
