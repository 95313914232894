import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Error403Module } from './403/error-403.module';
import { Error404Module} from './404/error-404.module'
import { Error500Module} from './500/error-500.module'


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    Error403Module,
    Error404Module,
    Error500Module
  ]
})
export class ErrorsModule { }
