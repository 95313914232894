import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id: 'digitalPlant',
        title: 'Digital Plant',
        translate: 'NAV.DIGITAL_PLANT',
        type: 'item',
        icon: 'domain',
        url: '/digital-plant',
        homePage: false,
        role: 'ROLE_DIGITAL_PLANT_LIST'
    },
    {
        id: 'mes',
        title: 'Mes',
        translate: 'NAV.MES',
        icon: 'kainet_format_list',
        type: 'collapsable',
        homePage: false,
        role: 'ROLE_MES_LIST',
        children: [
            {
                id: 'product',
                title: 'Poduct',
                translate: 'NAV.PRODUCT',
                type: 'item',
                // icon     : 'create_new_folder',
                url: '/mes/products',
                // customIcon: false
                homePage: false
            },
            {
                id: 'order',
                title: 'Order',
                translate: 'NAV.ORDER',
                type: 'item',
                // icon     : 'create_new_folder',
                url: '/mes/working-phases',
                // customIcon: false
                homePage: false
            },
            {
                id: 'werehouse',
                title: 'Poduct',
                translate: 'NAV.WAREHOUSE',
                type: 'item',
                // icon     : 'create_new_folder',
                url: '/mes/warehouse',
                // customIcon: false
                homePage: false
            },

        ]
    },
    {
        id: 'factory',
        title: 'Production',
        translate: 'NAV.PRODUCTION',
        icon: 'factory',
        customIcon: true,
        type: 'collapsable',
        homePage: false,
        children: [
            {
                id: 'asset',
                title: 'Asset',
                translate: 'NAV.ASSET',
                type: 'item',
                // icon     : 'kasset',
                url: '/assets',
                // customIcon: true
                homePage: false,
                role: 'ROLE_ASSET_LIST'
            },
            {
                id: 'process-manager',
                title: 'Process manager',
                translate: 'NAV.PROCESS_MANAGER',
                type: 'item',
                // icon     : 'share',
                url: '/process-manager',
                homePage: false,
                role: 'ROLE_PROCESS_MANAGER_LIST'
            },
            {
                id: 'dashboard-manager',
                title: 'Dashboard manager',
                translate: 'NAV.DAHBOARD_MANAGER',
                type: 'item',
                // icon     : 'share',
                url: '/dashboard-manager',
                homePage: false,
                role: 'ROLE_DASHBOARD_MANAGER_LIST'
            },

            {
                id: 'planning',
                title: 'Planning',
                translate: 'NAV.PLANNING',
                type: 'item',
                url: '/planning',
                homePage: false,
                role: 'ROLE_PLANNING_LIST'
            }
        ]
    },

    {
        id: 'administration',
        title: 'Administration',
        translate: 'NAV.ADM',
        icon: 'account',
        customIcon: true,
        type: 'collapsable',
        homePage: false,
        children: [
            {
                id: 'user',
                title: 'User',
                translate: 'NAV.USER',
                type: 'item',
                // icon     : 'person',
                url: '/users',
                // customIcon: false
                homePage: false,
                role: 'ROLE_USERS_LIST'
            },
            {
                id: 'group',
                title: 'Group',
                translate: 'NAV.GROUP',
                type: 'item',
                // icon     : 'group',
                url: '/groups',
                // customIcon: false
                homePage: false,
                role: 'ROLE_GROUPS_LIST'
            },
        ]
    },
    {
        id: 'services',
        title: 'Services',
        translate: 'NAV.SERVICES',
        icon: 'build',
        type: 'collapsable',
        homePage: false,
        children: [
            {
                id: 'maintenance',
                title: 'Maintenance',
                translate: 'NAV.MNTN',
                type: 'item',
                // icon     : 'build',
                url: '/maintenance/home',
                // customIcon: false
                homePage: false,
                role: 'ROLE_MAINTENANCE_LIST'
            },
            {
                id: 'collaboration',
                title: 'Collaboration',
                translate: 'NAV.COLLAB',
                type: 'item',
                // icon     : 'create_new_folder',
                url: '/collaboration',
                // customIcon: false
                homePage: false,
                role: 'ROLE_COLLABORATION_LIST'
            },
            {
                id: 'report',
                title: 'Report',
                translate: 'NAV.REPORT',
                type: 'item',
                // icon     : 'description',
                url: '/report',
                // customIcon: false
                homePage: false,
                role: 'ROLE_REPORT_LIST'
            },
        ]
    },
    // {
    //     id       : 'managerPlanner',
    //     title    : 'Planner',
    //     translate: 'NAV.PROLAT',
    //     type     : 'collapsable',
    //     icon     : 'build',
    //     children : [
    //         {
    //             id       : 'configuration',
    //             title    : 'Configuration',
    //             translate: 'NAV.CONFIGURATION',
    //             type     : 'item',
    //             // icon     : 'build',
    //             url      : '/managerPlanner/configuration',
    //             // customIcon: false
    //         },
    //         {
    //             id       : 'prolatUpload',
    //             title    : 'Prolat Upload',
    //             translate: 'NAV.PUP',
    //             type     : 'item',
    //             // icon     : 'build',
    //             url      : '/prolat/prolatUpload',
    //             // customIcon: false
    //         },
    //         {
    //             id       : 'plannerHistory',
    //             title    : 'History',
    //             translate: 'NAV.HISTORY',
    //             type     : 'item',
    //             // icon     : 'build',
    //             url      : '/prolat/history',
    //             // customIcon: false
    //         }
    //     ]

    // }
    // {
    //     id: 'history',
    //     title: 'History',
    //     translate: 'NAV.HISTORY',
    //     icon: 'history',
    //     customIcon: false,
    //     type: 'collapsable',
    //     homePage:false,
    //     role: 'ROLE_HISTORY_LIST',
    //     children: [
    //         {
    //             id       : 'dataCollection',
    //             title    : 'Data Collection',
    //             translate: 'NAV.DATA_COLLECTION',
    //             type     : 'item',
    //             // icon     : 'domain',
    //             url      : '/data-collection',

    //             homePage:false
    //         }
    //     ]
    // }

]
