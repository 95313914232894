import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { defaultHttpOptions } from '@kainet/kainet-common-lib';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuDefaultService {

  constructor(
    private http: HttpClient
  ) { }


  public getDefaultMenu(): Observable<{menuDefaultPage:string}> {
    return this.http.get<{menuDefaultPage:string}>('/api/admin/users/menuDefaultPage/', defaultHttpOptions());
  }
  public saveDefaultMenu(menuId: string): Observable<{menuDefaultPage:string}> {
    return this.http.put<{menuDefaultPage:string}>('/api/admin/users/menuDefaultPage/' + menuId, null, defaultHttpOptions());
  }
  
}
