<div id="error-403" fxLayout="column" fxLayoutAlign="center center">

    <div class="content" fxLayout="column" fxLayoutAlign="center center">

        <div class="error-code">403</div>

        <div class="message">Sorry but access forbidden</div>

    </div>

</div>