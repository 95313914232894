import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms'
import { TranslateModule } from '@ngx-translate/core';

import { LoginRoutingModule } from './login-routing.module';
import { LoginComponent } from './pages/login/login.component';
import { ResetPasswordRequestComponent } from './pages/login/reset-password-request.component';
import { ResetPasswordComponent } from './pages/login/reset-password.component';
import { CreatePasswordComponent } from './pages/login/create-password.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatIconModule } from '@angular/material/icon';
import { CoreModule } from '@app/core/core.module';
import { KainetCommonModule } from '@kainet/kainet-common-lib';

@NgModule({
  declarations: [
    LoginComponent,
    ResetPasswordRequestComponent,
    ResetPasswordComponent,
    CreatePasswordComponent
  ],
  imports: [
    CommonModule,
    LoginRoutingModule,
    BrowserAnimationsModule,
    MatIconModule,
    FormsModule,
    TranslateModule,
    CoreModule,
    KainetCommonModule
  ], 
  exports: [
    KainetCommonModule
  ],
})
export class LoginModule { }
