export const locale = {
    lang: 'en',
    data: {
        'NAV': {
            'PRODUCTION': 'Production',
            'DASHBOARD': 'Dashboard',
            'TELEMETRY':'Telemetry',
            'ADM': 'Administration',
            'USER': 'User',
            'GROUP': 'Group',
            'NODE': 'Node',
            'MNTN': 'Maintenance',
            'ASSET': 'Asset Manager',
            'REPORT': 'Report',
            'COLLAB': 'Collaboration',
            'PRODUCT': 'Product',
            'WAREHOUSE': 'Warehouse',
            'MES': 'MES',
            'ORDER': 'Order',
            'PROCESS_MANAGER': 'Process manager',
            'DAHBOARD_MANAGER':'Dashboard manager',
            'DIGITAL_PLANT': 'Digital Plant',
            'CONFIGURATION': 'Configurazioni',
            'PUP': 'Upload',
            'HISTORY': 'History',
            'PROLAT': 'Prolat',
            'PLANNING':'Planning',
            'DATA_COLLECTION':'Takt time',
            'SERVICES':'Services',
            'ANALITICS':'Analitics'
        },
        'MENU':{
            'STAR_BTN_SELECTED':'This is the landing page',
            'STAR_BTN_UNSELECTED':'Click on the star to select this landing page',

        }
    }
};
