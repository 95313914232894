import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Dashboard} from '@kainet/kainet-common-lib/public-api'
@Injectable({
  providedIn: 'root'
})
export class NavigationService {

constructor(private http: HttpClient) { }


public dasboardListByUser(): Observable<Array<Dashboard>> {
  return this.http.get<Array<Dashboard>>('/api/dashboard-manager/user')
}

}
