import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from '@app/core/services/login.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { Constants, KaiTranslationLoaderService, LoginResponse, NotificationService } from '@kainet/kainet-common-lib';
import { locale as english } from '../../i18n/en';
import { locale as italian } from '../../i18n/it';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginFormGroup: FormGroup;

  constructor(
    public _formBuilder: FormBuilder,
    private router: Router,
    private loginService: LoginService,
    private notifyService: NotificationService,
    private kaiTranslationLoaderService: KaiTranslationLoaderService,
    private translateService: TranslateService
  ) {
    this.kaiTranslationLoaderService.loadTranslations(english, italian);
  }

  ngOnInit(): void {
    this.loginFormGroup = this._formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });


  }

  login(): void {
    if (this.loginFormGroup.valid) {
      this.loginService.login(this.loginFormGroup.value).subscribe(
        (res: LoginResponse) => {
          this.showToasterSuccess();
          if (!res.firstLogin) {
            this.loginService.loadSuccess(true);

          }
        },
        (error: HttpErrorResponse) => {
          this.showToasterError(error);
          if (error && error.error && error.error.errorCode) {
            if (error.error.errorCode === Constants.serverErrorCode.credentialsExpired) {
              this.router.navigateByUrl(`login/resetExpiredPassword?resetToken=${error.error.resetToken}`);
            }
          }
        }
      );
    } else {
      Object.keys(this.loginFormGroup.controls).forEach(field => {
        const control = this.loginFormGroup.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    }
  }
  showToasterSuccess(): void {
    this.notifyService.notificationSuccess(
      { 

        title: this.translateService.instant('MESSAGES_AUTH.SUCCESS'),
        body: ''

      });
  }

  showToasterError(errorResponse: HttpErrorResponse): void {
    if(errorResponse.status==401){
      errorResponse.error.message="KAINET_FORBIDDEN";
    }
    this.notifyService.notificationError(
      { 

        title: this.translateService.instant('MESSAGES_AUTH.AUTH_ERROR'), 
        body: this.kaiTranslationLoaderService.transateError(errorResponse,'MESSAGES_AUTH')
      
      });
  }
}
