import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ToolbarService {

    //string variable to change locale
    public changeLocale = new BehaviorSubject<string>('en');
    currentValue = this.changeLocale.asObservable();

    constructor() { }

    //changes the variable value
    changeValue(locale: string) {
        this.changeLocale.next(locale);
    }

}