<div id="login" class="inner-scroll" fxLayout="row" fxLayoutAlign="center">
  <div id="login-form-wrapper" fusePerfectScrollbar>

    <div id="login-form">

      <form name="createPassword" [formGroup]="createPassword" (ngSubmit)="onCreatePassword()">
        <mat-card style="max-height: 200vh;">
          <mat-card-title-group>
            <div class="logo">
              <img src="/assets/images/logos/logo_pw.png">
            </div>
          </mat-card-title-group>
          <mat-card-content>
            <span class="create-password-subtitle">{{ 'CREATEPASS.CREATEPASS' | translate }}</span>
            <div class="input-field">
                <mat-form-field appearance="outline">
                  <mat-label>{{ 'GENERAL.PASS' | translate }}</mat-label>
                  <input matInput type="password" formControlName="password">
                  <mat-icon matSuffix class="secondary-text">lock</mat-icon>
                  <mat-error *ngIf="createPassword.get('password').hasError('required')">
                    {{ 'GENERAL.MISSINGPASS' | translate }}
                  </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline">

                  <mat-label>{{ 'GENERAL.PASSAGAIN' | translate }}</mat-label>
                  <input matInput type="password" formControlName="confirmpassword">
                  <mat-icon matSuffix class="secondary-text">lock</mat-icon>
                  <mat-error *ngIf="createPassword.get('confirmpassword').hasError('required')">
                    {{ 'GENERAL.MISSINGPASS' | translate }}
                  </mat-error>
                  <mat-error *ngIf="createPassword.get('confirmpassword').hasError('passwordNotMatch')">
                    {{ 'GENERAL.NOMATCH' | translate }}
                  </mat-error>
  
                </mat-form-field>

            </div>
          </mat-card-content>
          <mat-card-actions>
            <button  class="primary-btn" mat-raised-button type="submit" [disabled]="!createPassword.valid">
              {{ 'GENERAL.SAVE' | translate }}
            </button>
            <button mat-raised-button class="secondary-btn" type="button" (click)="cancel()">
              {{ 'GENERAL.CANCEL' | translate }}
            </button>
          </mat-card-actions>
        </mat-card>
      </form>
    </div>
  </div>
</div>