import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

import { navigation } from 'app/navigation/navigation';
import { Router } from '@angular/router';
import { UtilsService } from '@core/services/utils.service';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from '../toolbar/i18n/en';
import { locale as italian } from '../toolbar/i18n/it';
import { ToolbarService } from '../toolbar/toolbar.service';
import { AuthService } from '@kainet/kainet-security-common-lib';
import { CommonUtilsService } from '@kainet/kainet-common-lib';

@Component({
  selector: 'toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ToolbarComponent implements OnInit, OnDestroy {
  horizontalNavbar: boolean;
  rightNavbar: boolean;
  hiddenNavbar: boolean;
  languages: any;
  navigation: any;
  selectedLanguage: any;
  userStatusOptions: any[];
  user: string;
  isAdmin: boolean = false;
  ///breadcrumbList: Observable<any> = new Observable<any>();
  breadcrumbList ={};
  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {FuseConfigService} _fuseConfigService
   * @param {FuseSidebarService} _fuseSidebarService
   * @param {TranslateService} _translateService
   */
  constructor(
    private _fuseConfigService: FuseConfigService,
    private _fuseSidebarService: FuseSidebarService,
    private _translateService: TranslateService,
    private _authService: AuthService,
    private _router: Router,
    private utilsService: UtilsService,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private _toolbarService: ToolbarService,
    private commonUtilsService: CommonUtilsService
  ) {
    // Set the defaults
    this.userStatusOptions = [
      {
        title: 'Online',
        icon: 'icon-checkbox-marked-circle',
        color: '#4CAF50'
      },
      {
        title: 'Away',
        icon: 'icon-clock',
        color: '#FFC107'
      },
      {
        title: 'Do not Disturb',
        icon: 'icon-minus-circle',
        color: '#F44336'
      },
      {
        title: 'Invisible',
        icon: 'icon-checkbox-blank-circle-outline',
        color: '#BDBDBD'
      },
      {
        title: 'Offline',
        icon: 'icon-checkbox-blank-circle-outline',
        color: '#616161'
      }
    ];

    this.languages = [
      {
        id: 'en',
        title: 'English',
        flag: 'us'
      },
      {
        id: 'it',
        title: 'Italiano',
        flag: 'it'
      }
    ];

    this._fuseTranslationLoaderService.loadTranslations(english, italian);

    this.navigation = navigation;

    // Set the private defaults
    this._unsubscribeAll = new Subject();

    _translateService.addLangs(['en', 'it']);
    if (localStorage.getItem('locale')) {
        _translateService.setDefaultLang(localStorage.getItem('locale'));
        _translateService.use(localStorage.getItem('locale'));
    } else {
        _translateService.setDefaultLang('en');
        _translateService.use('en');
        localStorage.setItem('locale', 'en');
    }
  }

  /**
  * Set the language
  *
  * @param lang
  */
  setLanguage(lang): void {
    // Set the selected language for the toolbar
    this.selectedLanguage = lang;
    this._toolbarService.changeValue(this.selectedLanguage.id);

    // Use the selected language for translations
    this._translateService.use(this.selectedLanguage.id);
    this._translateService.setDefaultLang(this.selectedLanguage.id);
    this._translateService.currentLang = this.selectedLanguage.id;
    localStorage.setItem('locale', this.selectedLanguage.id);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    try {
      this.user = this._authService.getLoggedUser();
    } catch (error) {

    }

    // Check if user has admin roles
    const roles: Array<string> = this._authService.getRolesLoggedUser();
    if (roles.includes('ROLE_GROUPS_LIST') && roles.includes('ROLE_USERS_LIST')) {
      this.isAdmin = true;
    } else {
      this.isAdmin = false;
    }

    // Subscribe to the config changes
    this._fuseConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((settings) => {
        this.horizontalNavbar = settings.layout.navbar.position === 'top';
        this.rightNavbar = settings.layout.navbar.position === 'right';
        this.hiddenNavbar = settings.layout.navbar.hidden === true;
      });

    // Set the selected language from default languages
    this.selectedLanguage = _.find(this.languages, { id: this._translateService.currentLang });

    //this.breadcrumbList = this.commonUtilsService.breadcrumbOB();
    this.commonUtilsService.breadcrumbOB().subscribe(res => this.breadcrumbList = res);

  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebarOpen(key): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }

  /**
   * Search
   *
   * @param value
   */
  search(value): void {
    // Do your search here...
    // console.log(value);
  }

  logout() {
    this._authService.logout();
    this._router.navigate(['/login']);
    // window.location.reload();
  }

  userProfile() {
    this._router.navigate(['/user-profile']);
    // window.location.reload();
  }

  admSettings() {
    this._router.navigate(['/user-settings']);
  }
  navigate(url: string, param: object) {
    if (url) {
      if (param) {
        this._router.navigate([url,param]);
      }else{
        this._router.navigate([url]);
      }

    }

  }
}
