import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from '@app/core/services/login.service';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { LoginRequest } from '@kainet/kainet-common-lib';
import { AuthService } from '@kainet/kainet-security-common-lib';
import { locale as english } from '../../i18n/en';
import { locale as italian } from '../../i18n/it';

@Component({
  selector: 'create-password',
  templateUrl: './create-password.component.html',
  styleUrls: ['./create-password.component.scss']
})
export class CreatePasswordComponent {

  activateToken = '';
  valid = false;
  notBeNeull = false;
  createPassword: FormGroup = this._formBuilder.group({
    password: ['', Validators.required],
    confirmpassword: ['', Validators.required]
  }, { validators: this.password.bind(this) });

  constructor(
    public _formBuilder: FormBuilder,
    private router: Router,
    private _fuseConfigService: FuseConfigService,
    private loginService: LoginService,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService
  ) {
    this._fuseTranslationLoaderService.loadTranslations(english, italian);

    // Configure the layout
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        toolbar: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    };
  }

  onCreatePassword(): void {
    const user = AuthService.getUser();
    const loginRequest: LoginRequest = { username: user, password: this.createPassword.get('password').value };

    this.loginService.activate(loginRequest).subscribe(() => { })


  }
  password(formGroup: FormGroup): boolean {
    const { value: password } = formGroup.get('password');
    const { value: confirmPassword } = formGroup.get('confirmpassword');
    const matched: boolean = password === confirmPassword;
    if (password !== '' && confirmPassword !== '' && matched) {
      formGroup.controls.confirmpassword.setErrors(null);
      formGroup.controls.password.setErrors(null);

    } else if (password !== '' && confirmPassword !== '' && !matched) {
      formGroup.controls.confirmpassword.setErrors({ passwordNotMatch: true });
      formGroup.controls.password.setErrors({ passwordNotMatch: true });

    }
    return matched;
  }
  cancel(): void {
    this.router.navigate(['/login']);
  }
}
