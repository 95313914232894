<mat-toolbar class="p-0 mat-elevation-z1">
    <div class="breadcrumb-content">
        <div *ngFor="let item of breadcrumbList; let last = last" class="breadcrumb-item">
          <span (click)="navigate(item.redirect,item.param)" class="breadcrumb-link">{{ item.displayName|translate }}</span>
          <mat-icon class="separeate" *ngIf="!last">keyboard_arrow_right</mat-icon>
        </div>
      </div>

    <div fxFlex fxFill fxLayout="row" fxLayoutAlign="end center">
      <div class="toolbar-separator"></div>
        <div class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">

            <button mat-button [matMenuTriggerFor]="userMenu"
                    class="user-button">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <mat-icon class="m-12">person</mat-icon>
                    <span class="username mr-12" fxHide fxShow.gt-sm>{{user}}</span>
                    <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
                </div>
            </button>

            <mat-menu #userMenu="matMenu" [overlapTrigger]="false">

                <button mat-menu-item (click)="userProfile()">
                    <mat-icon>account_circle</mat-icon>
                    <span>{{ 'NAVBAR.MYPROFILE' | translate }}</span>
                </button>

                <!-- <button mat-menu-item (click)="admSettings()" *ngIf="isAdmin">
                    <mat-icon>settings</mat-icon>
                    <span>{{ 'NAVBAR.SETTINGS' | translate }}</span>
                </button> -->

                <button mat-menu-item (click)="logout()">
                    <mat-icon>exit_to_app</mat-icon>
                    <span>{{ 'NAVBAR.LOGOUT' | translate }}</span>
                </button>

            </mat-menu>

            <div class="toolbar-separator"></div>

            <button mat-button fxHide fxShow.gt-xs
                    class="language-button"
                    [matMenuTriggerFor]="languageMenu">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <!-- <img class="flag mr-8" [src]="'assets/icons/flags/'+selectedLanguage.flag+'.png'"> -->
                    <span class="iso text-uppercase mr-12">{{selectedLanguage.id}}</span>
                    <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
                </div>
            </button>

            <mat-menu #languageMenu="matMenu" [overlapTrigger]="false">

                <button mat-menu-item *ngFor="let lang of languages" (click)="setLanguage(lang)">
                    <span fxLayout="row" fxLayoutAlign="start center">
                        <!-- <img class="flag mr-16" [src]="'assets/icons/flags/'+lang.flag+'.png'"> -->
                        <span class="iso">{{lang.title}}</span>
                    </span>
                </button>

            </mat-menu>


        </div>

    </div>

</mat-toolbar>
