export const locale = {
    lang: 'en',
    data: {
        'USERPROFILE': {
            'EMAIL': 'Email',
            'FNAME': 'Firstname',
            'SNAME': 'Surname',
            'CHANGEPASS': 'CHANGE PASSWORD',
            'CANCEL': 'CANCEL',
            'SAVE': 'SAVE'
        },
        'ERR': {
            'EREQ': 'Email is required',
            'FREQ': 'Firstname is required',
            'SREQ': 'Surname is required',
            'EVALID': 'Please enter a valid email',
            'FVALID': 'Please enter a valid firstname',
            'SVALID': 'Please enter a valid surname'
        }
    }
};
