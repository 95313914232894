export const locale = {
    lang: 'en',
    data: {
        'NAVBAR': {
            'MYPROFILE': 'My Profile',
            'LOGOUT': 'Logout',
            'SETTINGS': 'Settings'
        }
    }
};
