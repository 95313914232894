import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { MenuDefaultService } from '@core/services/menu-default.service';

@Component({
    selector       : 'fuse-navigation',
    templateUrl    : './navigation.component.html',
    styleUrls      : ['./navigation.component.scss'],
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FuseNavigationComponent implements OnInit
{
    @Input()
    layout = 'vertical';

    @Input()
    navigation: any;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     *
     * @param {ChangeDetectorRef} _changeDetectorRef
     * @param {FuseNavigationService} _fuseNavigationService
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        private menuDefaultService: MenuDefaultService
    )
    {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Load the navigation either from the input or from the service
        this.navigation = this.navigation || this._fuseNavigationService.getCurrentNavigation();

        // Subscribe to the current navigation changes
        this._fuseNavigationService.onNavigationChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {

                // Load the navigation
                this.navigation = this._fuseNavigationService.getCurrentNavigation();

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });

        // Subscribe to navigation item
        merge(
            this._fuseNavigationService.onNavigationItemAdded,
            this._fuseNavigationService.onNavigationItemUpdated,
            this._fuseNavigationService.onNavigationItemRemoved
        ).pipe(takeUntil(this._unsubscribeAll))
         .subscribe(() => {

             // Mark for check
             this._changeDetectorRef.markForCheck();
         });
         this.menuDefaultService.getDefaultMenu().subscribe(res => {
            this.reloadHomePage(res.menuDefaultPage)
        })
        this._fuseNavigationService.onNavigationHomePageChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe(homepage=>this.reloadHomePage(homepage))
    }
    reloadHomePage(homePage) {
        for (let index = 0; index < this.navigation.length; index++) {
            const item = this.navigation[index];
  
            this.recursiveReloadHomePage(item,homePage);
        }
        this._changeDetectorRef.markForCheck();

    }
    recursiveReloadHomePage(item,homePage){
        if(item.children&&item.children.length){
            for (let index = 0; index < item.children.length; index++) {
                const element = item.children[index];
                this.recursiveReloadHomePage(element,homePage);
            }
        }else{
            if (item.id == homePage) {
                item.homePage = true;
            } else {
                item.homePage = false;
            }
        }
    }
}
