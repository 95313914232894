import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
@Injectable({
  providedIn: 'root'
})
export class ExternalIconService {

  constructor(
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
  ) {
  }


  
  initIcon() {

    this.matIconRegistry.addSvgIcon(
      `knode`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/kainet-icon/ico_node.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `file-document-multiple`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/kainet-icon/file-document-multiple.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `factory`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/kainet-icon/factory.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `account`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/kainet-icon/account.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `kainet_analytics`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/kainet-icon/analytics.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `view-dashboard`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/kainet-icon/view-dashboard.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `shered`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-icons/outline/library_add_check.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `kasset`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-icons/outline/auto_awesome_motion.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `file_download`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-icons/outline/file_download.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `file_download_off`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-icons/outline/file_download_off.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `kainet_format_list`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-icons/outline/format-list-bulleted.svg')
    );



  }

}
