import {
  CommonUtilsService,
  NotificationEvent,
  NotificationService,
} from '@kainet/kainet-common-lib';
import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import { FuseNavigation, FuseNavigationItem } from '@fuse/types';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { NavigationService } from '@core/services/navigation.service';
import { Router } from '@angular/router';
import { MenuDefaultService } from '@core/services/menu-default.service';
import { SecurityCommonService } from '@kainet/kainet-security-common-lib';
const DATASOURCETYPE={
  NODERED: 'NODERED',
  GRAFANA: 'GRAFANA'
}
@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor(
    private toastr: ToastrService,
    private _fuseNavigationService: FuseNavigationService,
    private commonUtilsService: CommonUtilsService,
    private router: Router,
    private navigationService: NavigationService,
    public notificationService: NotificationService,
    private securityCommonService: SecurityCommonService,
    private _menuDefaultService: MenuDefaultService
  ) { }



  mapNotification() {
    this.notificationService.$successEvent.subscribe(
      (res: NotificationEvent) => {
        this.toastr.success(res.body, res.title);
      }
    );
    this.notificationService.$errorEvent.subscribe((res) => {
      this.toastr.error(res.body, res.title);
    });
    this.notificationService.$infoEvent.subscribe((res) => {
      this.toastr.info(res.body, res.title);
    });
    this.notificationService.$warningEvent.subscribe((res) => {
      this.toastr.warning(res.body, res.title);
    });
  }

  navigateToDashboard(id) {
    const parent: FuseNavigation =
      this._fuseNavigationService.getNavigationItemParent(id);

    const b = [];
    if (parent) {
      b.push({ displayName: parent.translate, redirect: parent.url });
    }
    const dashboard: FuseNavigation =
      this._fuseNavigationService.getNavigationItem(id);
    b.push({ displayName: dashboard.title, redirect: dashboard.url });

    setTimeout(() => {
      this.commonUtilsService.sendBreadcrumb(b);
    }, 350);
    this.router.navigate([dashboard.url], { queryParams: { back: true }, });
  }


  sendBreadcrumb(item: FuseNavigation) {
    const parent: FuseNavigation =
      this._fuseNavigationService.getNavigationItemParent(item.id);

    const b = [];
    if (parent) {
      b.push({ displayName: parent.translate, redirect: parent.url });
    }
    b.push({ displayName: item.translate, redirect: item.url });
    setTimeout(() => {
      this.commonUtilsService.sendBreadcrumb(b);
    }, 350);
  }
  refreshDashbourdMenu(dashboardHidden: boolean,telemetryHidden: boolean) {
    this.navigationService
      .dasboardListByUser()
      .subscribe((dashbords: Array<any>) => {
        
        let grafanaNavigation: FuseNavigation = {
          id: "dashboard",
          title: "Dashboard",
          translate: "NAV.DASHBOARD",
          type: "collapsable",
          icon: "view-dashboard",
          customIcon: true,
          hidden: dashboardHidden,
          role:"ROLE_DASHBOARD_LIST",
          children: [],
        };
        let noderedNavigation: FuseNavigation = {
          id: "telemetry",
          title: "Telemetry",
          translate: "NAV.TELEMETRY",
          type: "collapsable",
          icon: "dvr",
          customIcon: false,
          hidden: telemetryHidden, 
          role:"ROLE_TELEMETRY_LIST",
          children: [],
        };
        //dash.children = [];

        dashbords.forEach((dashbord: { id: string; name: string }) => {
          let children: FuseNavigation = {
            id: dashbord.id,
            title: dashbord.name,
            type: "item",
            url: "/dashboard/" + dashbord.id,
            translate: dashbord.name
            // icon     : 'domain',
          };
          if(dashbord['dataSourceType']==DATASOURCETYPE.GRAFANA){
            children.role="ROLE_DASHBOARD_LIST";
            grafanaNavigation.children.push(children);
          }else if(dashbord['dataSourceType']==DATASOURCETYPE.NODERED){
            children.role="ROLE_TELEMETRY_LIST"
            noderedNavigation.children.push(children)
          }
        });
        //TELEMETRY NODERED
        let existTelemetry = this._fuseNavigationService.getNavigationItem("telemetry") ? true : false;
        if (existTelemetry &&noderedNavigation.children.length) {
          this._fuseNavigationService.removeNavigationItem("telemetry");
          this._fuseNavigationService.addNavigationItem(noderedNavigation, "start");
        } else if(!existTelemetry &&noderedNavigation.children.length){
          this._fuseNavigationService.addNavigationItem(noderedNavigation, "start");
        } else{
          this._fuseNavigationService.removeNavigationItem("telemetry");
        }
        
        //DASHBOARD GRAFANA
        let existDashboard = this._fuseNavigationService.getNavigationItem("dashboard") ? true : false;
        if (existDashboard && grafanaNavigation.children.length) {
          this._fuseNavigationService.removeNavigationItem("dashboard");
          this._fuseNavigationService.addNavigationItem(grafanaNavigation, "start");
        } else if(!existDashboard && grafanaNavigation.children.length){
          this._fuseNavigationService.addNavigationItem(grafanaNavigation, "start");
        } else{
          this._fuseNavigationService.removeNavigationItem("dashboard");
        }
      });
  }

  hideNoAuthenticatorMenu(roleMappingMap: Map<string, boolean>): void {
    this.refreshDashbourdMenu(!roleMappingMap.get('ROLE_DASHBOARD_LIST'),!roleMappingMap.get('ROLE_TELEMETRY_LIST'));
    let navigationList:FuseNavigation[] = this._fuseNavigationService.getCurrentNavigation();
    this.recursiveHideMenuItem(navigationList,roleMappingMap);
  }


  recursiveHideMenuItem(navigationList:FuseNavigation[],roleMappingMap: Map<string, boolean>){
    for (let index = 0; index < navigationList.length; index++) {
      const nav = navigationList[index];

      if(nav.role ){

        let hidden=roleMappingMap.has(nav.role)?!roleMappingMap.get(nav.role):true;
        //enable parent 
        if(hidden==false){
          let parent=this._fuseNavigationService.getNavigationItemParent(nav.id);
          this.hideMenuItem(parent.id,hidden);
        }
        this.hideMenuItem(nav.id,hidden);
      }
      if(nav.children&&nav.children.length>0){
        this.recursiveHideMenuItem(nav.children,roleMappingMap);
        let currentParent:FuseNavigation=this._fuseNavigationService.getNavigationItem(nav.id);
        let hiddenList:boolean[]=currentParent.children.map(cl=>cl.hidden);
        if(!hiddenList.includes(false)&&currentParent.role==undefined){
          this.hideMenuItem(currentParent.id,true);
        }

      }
    }

  }

  hideMenuItem(itemName:string,hidden:boolean){  
     this._fuseNavigationService.updateNavigationItem(itemName, {
     hidden: hidden
     });
  }
  getFirstNavigation(): void {


    this._menuDefaultService.getDefaultMenu().subscribe(res => {
      let home = this._fuseNavigationService.getNavigationItem(res.menuDefaultPage);
      if (home) {
        this.sendBreadcrumb(home);
        this.router.navigate([home.url]);
      } else {
        this.defaultNavigation()


      }
    },error=>this.defaultNavigation())




  }
  defaultNavigation() {
    let landingChild: FuseNavigationItem = {
      id: null,
      title: null,
      type: null,
    };
    let roleList=this.securityCommonService.getListRoleFromLoggedUser();
    if(!roleList.includes("ROLE_ASSET_LIST")){
      landingChild= this._fuseNavigationService.getFirstNavigationUrl(roleList);
    }else{
      landingChild= this._fuseNavigationService.getNavigationItem('asset');
      
    }

    

    if(!landingChild){
      this.router.navigate(['/']);
    }else{
    this.sendBreadcrumb(landingChild);
    this.router.navigate([landingChild.url]);
  }
}

}


