<div id="resetPassword" fxLayout="row" fxLayoutAlign="center">
  <div id="password-change-form-wrapper">

    <div id="password-change-form">

      <form name="resetPassword" [formGroup]="resetPassword" (ngSubmit)="resetPasswordSubmit()">
        <mat-card style="max-height: 200vh;">
          <mat-card-title-group>
            <div class="logo">
              <img src="/assets/images/logos/logo_pw.png">
            </div>
          </mat-card-title-group>

          <mat-card-content>
            <span class="change-password-subtitle">{{ 'RESET.CHANGEPASS' | translate }}</span>
            <div class="input-field">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'GENERAL.PASS' | translate }}</mat-label>
                <input matInput type="password" formControlName="password">
                <mat-icon matSuffix class="secondary-text">lock</mat-icon>
                <mat-error *ngIf="resetPassword.get('password').hasError('required')">
                  {{ 'GENERAL.MISSINGPASS' | translate }}
                </mat-error>
              </mat-form-field>
              <mat-form-field appearance="outline">

                <mat-label>{{ 'GENERAL.PASSAGAIN' | translate }}</mat-label>
                <input matInput type="password" formControlName="confirmpassword">
                <mat-icon matSuffix class="secondary-text">lock</mat-icon>
                <mat-error *ngIf="resetPassword.get('confirmpassword').hasError('required')">
                  {{ 'GENERAL.MISSINGPASS' | translate }}
                </mat-error>
                <mat-error *ngIf="resetPassword.get('confirmpassword').hasError('passwordNotMatch')">
                  {{ 'GENERAL.NOMATCH' | translate }}
                </mat-error>

              </mat-form-field>
            </div>
          </mat-card-content>

          <mat-card-actions>
            <div fxLayout="column" class="tb-action-button">
              <button mat-raised-button class="submit-button" [disabled]="resetPassword.invalid">
                {{ 'GENERAL.SAVE' | translate }}
              </button>
            </div>
          </mat-card-actions>
        </mat-card>
      </form>
    </div>
  </div>
</div>